import { useState, useRef, useEffect } from 'react';


// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Link,
    Box,
    Button,
    ButtonBase,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import useECAPAxios from 'utils/ecapAxios';
import { IntakeFieldPosVals } from 'services/constants/attribute-constants';
import { useNavigate } from 'react-router';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { useLocation } from 'react-router-dom';
import { useSub } from 'hooks/useSub';
import SplashDetail from 'ui-component/splash/splash-detail';


// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = ({ history }) => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const useAxiosInstance = useECAPAxios();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    
    const [{ loading: notificationsDataLoading, error: notificationsFetchError }, GetNotificationsData] = useAxiosInstance(`/api/notification`, { manual: true });
    const [{ loading: readNotificationsInProgress, error: readNotificationsError }, ReadNotificationsApi] = useAxiosInstance({method: 'POST', url: `/api/notification/set/read`}, { manual: true });

    const [notificationsData, setNotificationsData] = useState([]);
    const [splashContext, setSplashContext] = useState(null);

    const location = useLocation();

    
    useSub('refreshNotifications', (data) => {
        if(!location?.pathname?.toLowerCase()?.includes("login")) {
            getNotificationsFromApi();
        }
    });

    useEffect(() => {
        if(!location.pathname?.toLowerCase().includes("login")) {
            getNotificationsFromApi();
            // alert(location.pathname)
        }
        
    },[location.pathname]);

    const clickSplash = (notSplashDets) => {
        setSplashContext(notSplashDets);
    }

    // useEffect(() => {
    //     alert('general')
    //     getNotificationsFromApi();
    // }, []);

    const getNotificationsFromApi = () => {
        GetNotificationsData().then((resp) => {
            let nots = [];
            if (resp && resp.data && resp.data.notifications && resp.data.notifications.length) {
                nots = resp.data.notifications.map(noti => {
                    let notItem = {};
                    notItem.datetime = noti.notificationDate;
                    notItem.title = getNotificationTitle(noti);
                    notItem.message = getNotificationMessageBody(noti);
                    notItem.isRead = noti.isRead;
                    notItem.id = noti.id;

                    return notItem;
                });

                setNotificationsData(nots);
            }
        });
    }

    const getNotificationTitle = (noti) => {
        if(noti.notificationTypeName == "Different Worker") {
            if(noti.placementProgramCode == IntakeFieldPosVals.PRIMMATCHNEED_FOSTER) {
                return "Placement Made";
            }
            else if(noti.placementProgramCode == IntakeFieldPosVals.PRIMMATCHNEED_ADOPTION) {
                return "Match Made";
            }
            else {
                return "Placement Made";
            }
        }
        else if(noti.notificationTypeName == "Future Placement") {
            return "Placement Starting";
        }
        else if(noti.notificationTypeName == "WORKERSPLASH") {
            return "Global Notification";
        }
    }

    const getNotificationMessageBody = (noti) => {
        if(noti.notificationTypeName == "Different Worker") {
            if(noti.placementProgramCode == IntakeFieldPosVals.PRIMMATCHNEED_FOSTER) {
                return (<>A placement with <Link onClick={e => navigate(`/ClientProfile?clientId=${noti.clientId}`)}>{noti.clientFirstName} {noti.clientLastName}</Link> and <Link onClick={e => navigate(`/Provider/Profile/${noti.providerId}`)}>{noti.providerName}</Link> has been made with your name as the assigned worker.</>);
            }
            else if(noti.placementProgramCode == IntakeFieldPosVals.PRIMMATCHNEED_ADOPTION) {
                return (<>A match with <Link onClick={e => navigate(`/ClientProfile?clientId=${noti.clientId}`)}>{noti.clientFirstName} {noti.clientLastName}</Link> and <Link onClick={e => navigate(`/Provider/Profile/${noti.providerId}`)}>{noti.providerName}</Link> has been made with your name as the assigned worker.</>);
            }
        }
        else if (noti.notificationTypeName == "Future Placement") {
            if(noti.placementProgramCode == IntakeFieldPosVals.PRIMMATCHNEED_FOSTER) {
                return (<>A Foster placement is starting today with <Link onClick={e => navigate(`/ClientProfile?clientId=${noti.clientId}`)}>{noti.clientFirstName} {noti.clientLastName}</Link> and <Link onClick={e => navigate(`/Provider/Profile/${noti.providerId}`)}>{noti.providerName}</Link>. If any details of this placement have changed, please edit the placement to reflect the current information.</>);
            }
            else if(noti.placementProgramCode == IntakeFieldPosVals.PRIMMATCHNEED_ADOPTION) {
                return (<>An Adoption placement is starting today with <Link onClick={e => navigate(`/ClientProfile?clientId=${noti.clientId}`)}>{noti.clientFirstName} {noti.clientLastName}</Link> and <Link onClick={e => navigate(`/Provider/Profile/${noti.providerId}`)}>{noti.providerName}</Link>. The provider's status is currently 'Matched'.  Please change the status if needed.  If any details of this placement have changed, please edit the placement to reflect the current information.</>);
            }
        }
        else if (noti.notificationTypeName == "FFP Family Response") {
            return (<>Family ({noti.familyName}) has responded to the Safe Summary for {noti.clientsList}</>);
        }
        else if (noti.notificationTypeName == "Ffp Family Attachment Upload") {
            return (<>Family ({noti.familyName}) has submitted a document for the {noti.memberTypeDesc} - {noti.requirementName} requirement.</>);
        }
        else if (noti.notificationTypeName == "WORKERSPLASH") {
            return (<>Global notification (<Link onClick={e => clickSplash(noti)}>{noti.addInfo?.name}</Link>) is created by {noti.workerName}.</>);
        }
    }
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        if(!open) {
            if(notificationsData.filter(x => !x.isRead).length) {
                
                setTimeout(() => {
                    ReadNotificationsApi({data: {NotificationIds: notificationsData.map(x => x.id)}}).
                    then(res => {
                        if(res.data ) {
                            if(res.data.isInErrorState) {

                            }
                            else{
                                var updatedNotificationsData = notificationsData.map(x => { x.isRead = true; return x;});
                                setNotificationsData(updatedNotificationsData);
                            }
                        }
                    })
                    .catch((err) => {

                    })

                }, 3000)
            }
        }
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => {
        if (event?.target.value) setValue(event?.target.value);
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    {/*<Avatar*/}
                    {/*    variant="rounded"*/}
                    {/*    sx={{*/}
                    {/*        ...theme.typography.commonAvatar,*/}
                    {/*        ...theme.typography.mediumAvatar,*/}
                    {/*        transition: 'all .2s ease-in-out',*/}
                    {/*        background: theme.palette.secondary.light,*/}
                    {/*        color: theme.palette.primary.dark,*/}
                    {/*        '&[aria-controls="menu-list-grow"],&:hover': {*/}
                    {/*            background: theme.palette.secondary.dark,*/}
                    {/*            color: theme.palette.secondary.light*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    ref={anchorRef}*/}
                    {/*    aria-controls={open ? 'menu-list-grow' : undefined}*/}
                    {/*    aria-haspopup="true"*/}
                    {/*    onClick={handleToggle}*/}
                    {/*    color="inherit"*/}
                    {/*>*/}
                    {/*    <NotificationsIcon />*/}
                    {/*</Avatar>*/}
                    <Badge badgeContent={notificationsData.filter(x => !x.isRead).length} sx={{
                        "& .MuiBadge-badge": {
                            color: "white",
                            backgroundColor: "red"
                        }
                    }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                        <NotificationsIcon sx={{ fontSize: 30 }} />
                    </Badge>

                </ButtonBase>

                

            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                style={{ zIndex: 100 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        
                                        <Grid item xs={12}>
                                            <div
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden', overflowY: 'scroll' }}
                                            >
                                                
                                                <NotificationList notificationsDataLoading={notificationsDataLoading} notificationsData={notificationsData} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>

            {!!splashContext && <SplashDetail splashId={splashContext?.id} showPrimAction={false} />}
        </>
    );
};

export default NotificationSection;
