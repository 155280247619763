import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { Button, CssBaseline, StyledEngineProvider, Typography } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import Amplify, { Auth, Hub } from 'aws-amplify';

import awsconfig from './aws-config';
import useAuthConfig from 'hooks/useAuthConfig';
import { useLocation, useNavigate } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';
import CustomDialog from 'ui-component/extended/CustomDialog';
import IdleTimerContext, { IdleTimerProvider } from 'context/IdleTimerProvider';
import useIdleTimerEcap from 'hooks/useIdleTimer';
import useAuth from 'hooks/useAuth';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import config from 'config';
import { usePub } from 'hooks/useSub';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


const IdleTimerContainer = () => {
    const cognitoTokenRefreshTimeInMinutes  = 595;
    const inactivityTimeInMinutes  = 560;

    const location = useLocation();
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const { idleTimerConfig, setIdleTimerConfig } = useIdleTimerEcap();

    const onCognitoTokenTimeout = async () => {
        if(!location.pathname.includes("login")) {
        const userFromCog = await Auth.currentUserPoolUser();
        Auth.currentSession().then(session => {
            const refresh_token = session.getRefreshToken();
            userFromCog.refreshSession(refresh_token, (refErr, refSession) => {
                if (refErr) {
                    throw refErr;
                }
                else{
                    //this provide new accessToken, IdToken, refreshToken
                    // you can add you code here once you get new accessToken, IdToken, refreshToken
                    const accessToken = refSession.accessToken.jwtToken;
                    const idToken = refSession.idToken.jwtToken;
                    const refToken = refSession.refreshToken.jwtToken;
                    let authResp = JSON.parse(JSON.stringify(auth));
                    authResp.authN_IdToken = idToken;
                    authResp.authN_AccessToken = accessToken;
                    authResp.authN_RefreshToken = refToken;
                    setAuth(authResp);

                    localStorage.setItem("AuthDetails", JSON.stringify(authResp));
                    if(authResp.locale) {
                        localStorage.setItem("EcapLocale", authResp.locale)
                    }
                }
            });
        })
    }
    }

    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user
        if(!isInactivityOpen && !location.pathname.includes("login")) {
            setIsInactivityOpen(true);
            const inactivityModalTimeoutTimer1 = setTimeout(() => {
                setIsInactivityOpen(false);
                localStorage.removeItem("AuthDetails");
                navigate('/login');
            }, 60000);
            setinactivityModalTimeoutTimer(inactivityModalTimeoutTimer1);
        }
    }
    // // Hook
    const idleTimerProps = {
        timeout: 1000 * 60 * inactivityTimeInMinutes,
        name: 'inactivity-timer',
        onIdle,
        
    }
    // // Hook
    const cognitoTokenRefreshTimerProps = {
        timeout: 1000 * 60 * cognitoTokenRefreshTimeInMinutes,
        name: 'cognito-refresh-timer',
        onIdle: onCognitoTokenTimeout,
        
    }
    let idleTimer = useIdleTimer(idleTimerProps);
    let cognitoTokenRefreshTimer = useIdleTimer(cognitoTokenRefreshTimerProps);
    //let cognitoTokenTimer = useIdleTimer(idleTimerProps);
    useEffect(() => {

        if(setIdleTimerConfig) {
            setIdleTimerConfig({idleTimerProps, idleTimer})
            }
    }, [])

    const [isInactivityOpen, setIsInactivityOpen] = useState(false);
    const [inactivityModalTimeoutTimer, setinactivityModalTimeoutTimer] = useState(null);
    const [inactivityDialogTimerSecs, setInactivityDialogTimerSecs] = useState(300);
    const handleCloseInactivity = () => {
        setIsInactivityOpen(false);
    }

    const stayLoggedIn = () => {
        clearTimeout(inactivityModalTimeoutTimer);
        setinactivityModalTimeoutTimer(null);
        setIsInactivityOpen(false);
    }

    return (<CustomDialog title='Inactivity' isOpen={isInactivityOpen} handleClose={handleCloseInactivity}>
        <Typography>You are about to logout due to inactivity. Please continue to stay logged in.</Typography>
        <Button onClick={stayLoggedIn}>Stay Logged in</Button>
    </CustomDialog>)
}

// ==============================|| APP ||============================== //

function App() {
    
    const [connection, setConnection] = useState<HubConnection>(null);
    const authzConfig = useAuthConfig();
    const { auth, } = useAuth();
    const publish = usePub();

    useEffect(() => {
        const connection1 = new HubConnectionBuilder()
        .withUrl(config.baseApiUrl + "/ecap/realtime/updates")
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Error)
        .build();
        // Setting the connection timeout
        connection1.serverTimeoutInMilliseconds = 180000; // 3 mins

        setConnection(connection1);
    }, []);


    useEffect(() => {
        if(connection) {
            connection.on("updateManageUsers", (message) => {
                //alert(message);
                publish("updateManageUsers", message);
            })
            connection.on("updateSafeSummaryList", (message) => {
                publish("updateSafeSummaryList", message);
            });

            connection.on("refreshNotifications", (message) => {
                publish("refreshNotifications", message);
            });

            connection.start();
        }

    }, [connection])
    
    const customization = useSelector((state: any) => state.customization);

    useEffect(() => {
        Amplify.configure(awsconfig.getAuthConfig(authzConfig.authConfig).Auth);
    }, [])
    


    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization, auth?.themeJson)}>
                <CssBaseline />
                <NavigationScroll>
                    <IdleTimerProvider>
                        <IdleTimerContainer />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Routes />
                        </LocalizationProvider>
                        
                    </IdleTimerProvider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
