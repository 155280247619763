import { useEffect, useState } from "react";
import useECAPAxios from 'utils/ecapAxios';
import { AxiosError, AxiosResponse } from "axios";
import { useEcapSnackNotificationHTML } from "hooks/useEcapSnackNotification";
import CustomDialog from "ui-component/extended/CustomDialog";
import { Box, Button } from "@mui/material";
import AnimateButton from "ui-component/extended/AnimateButton";
import { LoadingButton } from "@mui/lab";
import { handleResponse } from "utils/common-helpers";
import { useTheme } from "@mui/material/styles";
import Checklist from "@mui/icons-material/Checklist";

export interface SplashDetailProps {
  splashData?: any,
  splashId?: number,
  showPrimAction: boolean
}

function SplashDetail(props: SplashDetailProps) {
  const { setSnackbar } = useEcapSnackNotificationHTML();
  const useAxiosInst = useECAPAxios();
  const theme = useTheme();

  const [splashData, setSplashData] = useState(null);
  const [openSplash, setOpenSplash] = useState(false);

  const [{ loading: splashLoading }, getSplashByIdApi] = useAxiosInst({ url: `api/splash/${props.splashId}`, method: 'GET' }, { manual: true });
  const [{ loading: setSplashStatusInProgress }, setSplashStatusApi] = useAxiosInst({ url: `api/splash/set/view-status`, method: 'POST' }, { manual: true });

  useEffect(() => {
    if(!!(props.splashData && props.splashData.id)) {
      setOpenSplash(true);
      setSplashData(props.splashData);
    }
  }, [props.splashData])

  useEffect(() => {
    if(props.splashId) {
      getSplash();
    }
  }, [props.splashId])

  const getSplash = () => {
    if(props.splashId) {
        getSplashByIdApi({url: `api/splash/${props.splashId}`})
        .then((response: AxiosResponse) => {
          handleResponse(response, () => {setSplashData(response.data); setOpenSplash(true);}, setSnackbar);
        })
        .catch((error: AxiosError) => {
            setSnackbar({
                html: "Error with getting latest splash",
                variant: "error",
            })
        });
    }
  }

  const handleCloseSplash = () => {
    setOpenSplash(false);
  }

  const setSplashViewstatus = () => {
    const setSplashViewstatusReqBody = {
      WorkerSplashId: splashData?.id,
      IsRead: true
    };

    setSplashStatusApi({data: setSplashViewstatusReqBody})
    .then(response => {
      handleResponse(response, () => {setOpenSplash(false);}, setSnackbar);
    })
    .catch(err => {
      setSnackbar({
        html: "Error marking splash as read.",
        variant: "error",
    })
    })
  }
  
  return (
    <div style={{overflowX: 'hidden'}}>
    <CustomDialog title='Gloabl Notification' isOpen={openSplash} handleClose={handleCloseSplash} maxWidth="xl">
    <div style={{width: '70vw', overflowX: 'hidden'}}>
        <h2 style={{textAlign: 'center'}}>{splashData?.name}</h2>
        <p dangerouslySetInnerHTML={{__html: splashData?.content}} />
        <Box sx={{ mt: 2, width: '100%', display: 'inline-flex', justifyContent: 'space-between' }}>
              <Button
                name="cancelSplashBtn"
                onClick={() => {
                    setOpenSplash(false)
                }}
                disableElevation
                sx={{ ...theme.ecap.customInput }}
                variant="contained"
                color="secondary"
              >
                Close
              </Button>
              {props.showPrimAction && <AnimateButton>
              <LoadingButton
                name="markSplashStatusBtn"
                disableElevation
                disabled={setSplashStatusInProgress}
                loading={setSplashStatusInProgress}
                onClick={() => setSplashViewstatus()}
                variant="contained"
                color="primary"
              >
                Mark as Read
              </LoadingButton>
              </AnimateButton>}
            </Box>
      </div>
    </CustomDialog>
    </div>
  );
}

export default SplashDetail;