const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/fostercaretech.com/default'
    basename: '',
    defaultPath: '',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 2,
    baseApiUrl: "https://dccca-api-dev.fostercaretech.com",
    authZBaseApiUrl: "https://authz-dev.fostercaretech.com/API",
    appId: 'ECAP-E2-TFI',
    appName: "ECAP-E2-DCCCA-Dev",
    mapAPIKey: "abcd",
    // dummyAppId: 'ADE9DCFA-A380-435C-9074-CFAE839553F4',//This should be moved out since this has to be fetched from api based on the appId set above and must be aailable through Auth Context
    // dummyTenantId: '857A7B28-2DD8-456B-A96E-7412DF713927'
};

export default config;